// @flow

import { useQuery } from 'react-query';
import { TillTransaction, type IncludesT } from '@kwara/models/src';
import { fetchWithoutPagination } from './base';

async function fetchCurrentTillTransactions(opts) {
  return fetchWithoutPagination(TillTransaction, opts);
}

const defaultIncludes = ['branch'];
export function useCurrentTillTransactions(enabled: boolean, includes: IncludesT = defaultIncludes) {
  return useQuery(['current_till_transactions', includes], fetchCurrentTillTransactions, {
    enabled,
    placeholderData: []
  });
}
