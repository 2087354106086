import compact from 'lodash/fp/compact';

import { StepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import { IDCheckResponse } from '../../../models';
import CreditCheck from '../components/CreditCheck';
import LoanDetails from './components/LoanDetails';
import DisbursementPreview from './components/DisbursementPreview';
import MemberProfile from './components/MemberProfile';
import Recommendations from './components/Recommendations';
import SummaryAndReview from './components/SummaryAndReview';
import IncomeAndSecurity from './components/IncomeAndSecurity';
import CreditReport from '../components/CreditCheck/pages/CreditReport';

/**
 * this is a temporal function used to construct
 * incomeAndSecurity step for either KwaraKenya
 * or other SACCOs. Ones the AB testing is done,
 * the isKwaraKenyaOrE2E version will be released to all orgs
 */
function generateIncomeAndSecurityStep(isKwaraKenyaOrE2E: boolean, isCrbCheckEnabled: boolean, isV1: boolean) {
  if (isKwaraKenyaOrE2E) {
    return {
      incomeAndSecurity: {
        titleId: 'LoanAppraisal.IncomeAndSecurity.LeftNavigation.title',
        subtitleId: 'LoanAppraisal.IncomeAndSecurity.LeftNavigation.subTitle',
        actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'creditCheck' }],
        children: [
          {
            titleId: 'LoanAppraisal.IncomeAndSecurity.title',
            Component: IncomeAndSecurity.Component,
            validate: IncomeAndSecurity.validate,
            actions: [
              {
                appearsAs: 'next',
                behavesAs: 'next',
                destination: isCrbCheckEnabled && !isV1 ? 'creditCheck' : 'recommendations'
              }
            ]
          }
        ]
      }
    };
  }

  return {
    incomeAndSecurity: {
      titleId: 'LoanAppraisal.IncomeAndSecurity.LeftNavigation.title',
      subtitleId: 'LoanAppraisal.IncomeAndSecurity.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: isCrbCheckEnabled ? 'creditCheck' : 'recommendations'
        }
      ],
      children: [
        {
          titleId: 'LoanAppraisal.IncomeAndSecurity.title',
          Component: IncomeAndSecurity.Component,
          validate: IncomeAndSecurity.validate
        }
      ]
    }
  };
}

export type StepsArgs = {
  shouldRetry: boolean;
  hasError: boolean;
  canNext: boolean;
  isCrbCheckEnabled: boolean;
  shouldVerify: boolean;
  onVerify(): Promise<IDCheckResponse>;
  onRetry(): Promise<IDCheckResponse>;
  canApprove: boolean;
  canReject: boolean;
  isKwaraKenyaOrE2E: boolean;
  isV1: boolean;
};

export const steps = ({
  hasError,
  shouldRetry,
  isCrbCheckEnabled,
  shouldVerify,
  canNext,
  onVerify,
  canApprove,
  canReject,
  isKwaraKenyaOrE2E,
  isV1
}: StepsArgs) =>
  ({
    memberProfile: {
      titleId: 'LoanAppraisal.MemberProfile.LeftNavigation.title',
      subtitleId: 'LoanAppraisal.MemberProfile.LeftNavigation.subTitle',
      actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'loanDetails' }],
      children: [{ titleId: 'LoanAppraisal.MemberProfile.title', Component: MemberProfile.Component }]
    },
    loanDetails: {
      titleId: 'LoanAppraisal.LoanDetails.LeftNavigation.title',
      subtitleId: 'LoanAppraisal.LoanDetails.LeftNavigation.subTitle',
      actions: [
        {
          appearsAs: 'next',
          behavesAs: 'next',
          destination: 'incomeAndSecurity'
        }
      ],
      children: [
        {
          titleId: 'LoanAppraisal.LoanDetails.title',
          Component: LoanDetails.Component,
          validate: LoanDetails.validate
        },
        isV1
          ? {
              titleId: 'LoanAppraisal.DisbursementPreview.title',
              subtitleId: 'LoanAppraisal.DisbursementPreview.subtitle',
              Component: DisbursementPreview.Component
            }
          : null
      ].filter(Boolean)
    },
    ...generateIncomeAndSecurityStep(isKwaraKenyaOrE2E, isCrbCheckEnabled, isV1),
    creditCheck: {
      titleId: 'LoanAppraisal.CreditCheck.LeftNavigation.title',
      subtitleId: `LoanAppraisal.CreditCheck.LeftNavigation.subTitle.${
        isCrbCheckEnabled ? 'ForCrbEnabledUser' : 'ForNonCrbEnabledUser'
      }`,
      actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'recommendations' }],
      children: [
        {
          titleId: 'LoanAppraisal.CreditCheck.title',
          Component: CreditCheck.Component,
          validate: CreditCheck.validate,
          hideActions: { next: !canNext },
          actions: compact([
            shouldVerify && !canNext
              ? {
                  appearsAs: 'verify',
                  behavesAs: 'nextWithPromise',
                  destinationPath: 'creditCheck/2',
                  isPermitted: true,
                  isHidden: false,
                  onNext: onVerify
                }
              : null,
            hasError ? { appearsAs: 'skip', behavesAs: 'skip', destination: 'recommendations' } : null,
            shouldRetry
              ? { appearsAs: 'retry', behavesAs: 'nextWithPromise', destination: 'recommendations', onNext: onVerify }
              : null
          ])
        },
        {
          titleId: 'LoanAppraisal.CreditCheck.title',
          Component: CreditReport.Component,
          validate: CreditReport.validate
        }
      ]
    },
    recommendations: {
      titleId: 'LoanAppraisal.Recommendations.LeftNavigation.title',
      subtitleId: 'LoanAppraisal.Recommendations.LeftNavigation.subTitle',
      actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'summaryAndReview' }],
      children: [
        {
          titleId: 'LoanAppraisal.Recommendations.title',
          Component: Recommendations.Component,
          validate: Recommendations.validate,
          hideErrorBanner: true
        }
      ]
    },
    summaryAndReview: {
      titleId: 'LoanAppraisal.SummaryAndReview.LeftNavigation.title',
      subtitleId: 'LoanAppraisal.SummaryAndReview.LeftNavigation.subTitle',
      actions: [
        { appearsAs: 'reject', behavesAs: 'reject', isPermitted: canReject },
        { appearsAs: 'approve', behavesAs: 'approve', isPermitted: canApprove }
      ],
      children: [{ titleId: 'LoanAppraisal.SummaryAndReview.title', Component: SummaryAndReview.Component }]
    }
  } as StepConfig);
