import React from 'react';

import { DisbursementPreviewTable } from './components/Table';

function DisbursementPreview({ data }) {
  const loan = data.loan;

  return (
    <DisbursementPreviewTable
      principalAmount={loan.amount}
      netDisbursalAmount={loan.expectedNetDisbursalAmount}
      disbursementFees={loan.charges}
      refinancings={(loan.expectedLoanRefinancings || []).map(refinancing => ({
        loanId: refinancing.refinancedLoanId,
        loanName: refinancing.refinancedLoanName,
        amount: refinancing.expectedRefinanceAmount
      }))}
    />
  );
}

export default {
  Component: DisbursementPreview
};
