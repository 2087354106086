import React from 'react';

import { Currency, Date, DateTime } from '@kwara/components/src/Intl';
import { JournalEntryT } from '@kwara/models/src/models/JournalEntry';

import { Row, Cell } from '../../../../../components/Table';

type V1TableRowProps = {
  journalEntry: JournalEntryT;
};

export function V1TableRow({ journalEntry }: V1TableRowProps) {
  return (
    <Row>
      <Cell>{journalEntry.id}</Cell>
      <Cell>
        <Date value={journalEntry.bookingDate} />
      </Cell>
      <Cell>
        <DateTime value={journalEntry.createdAt} />
      </Cell>
      <Cell>{journalEntry.transactionId}</Cell>
      <Cell>
        {journalEntry.glAccountCode}
        <br />
        <span className="grey-400">{journalEntry.glAccountName}</span>
      </Cell>
      <Cell>{journalEntry.branchName}</Cell>
      <Cell align="right">
        {journalEntry.type === 'DEBIT' ? <Currency hideDecimals={false} value={journalEntry.amount} /> : null}
      </Cell>
      <Cell align="right">
        {journalEntry.type === 'CREDIT' ? <Currency hideDecimals={false} value={journalEntry.amount} /> : null}
      </Cell>
    </Row>
  );
}
