import { InferredModel } from 'GlobalTypes';
import { attr } from 'spraypaint';

import Base from '../../Base';

export const Refinancing = Base.extend({
  static: {
    jsonapiType: 'loan_refinancings'
  },
  attrs: {
    refinancedLoanId: attr(),
    refinancedLoanName: attr(),
    expectedRefinanceAmount: attr(),
    settledRefinanceAmount: attr(),
    completedAt: attr(),
    discardedAt: attr()
  }
});

export interface LoanRefinancingT extends Omit<InferredModel<typeof Refinancing>, 'errors'> {
  refinancedLoanId: string;
  refinancedLoanName: string;
  expectedRefinanceAmount: string;
  settledRefinanceAmount: string;
  completedAt: string;
  discardedAt: string;
}
