import * as React from 'react';
import cx from 'classnames';

import Banner from '@kwara/components/src/Banner';
import { If } from '@kwara/components/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { useBoolean } from '@kwara/lib/src/hooks/useBoolean';
import { LinkButton } from '@kwara/components/src/LinkButton';

import { LoanSubstepProps } from 'GlobalTypes';

import styles from './index.module.scss';

import { Container } from '../../../../../../../layouts';
import { AttributesRow } from '../../../../../../../components/AttributesRow';
import { TwoThirdsModal } from '../../../../../components/TwoThirdsModal';
import { getTwoThirdsRuleValues } from '../../../../../utils/getTwoThirdsRuleValues';
import { useAuth } from '../../../../../../../hooks';

interface IncomeDetailsPropTypes extends LoanSubstepProps {}

export function IncomeDetails({ data, addDataAndContinue, disableNextButton }: IncomeDetailsPropTypes) {
  const auth = useAuth();
  const isV1 = auth.isV1();
  const [shownModal, { setToTrue: showModal, setToFalse: hideModal }] = useBoolean();

  const { member, loan } = data;
  const { loanApplication = {}, remittance = {} } = loan;
  const { amount: repaymentAmount = 0 } = remittance;
  const { profession } = member;

  const {
    employmentStatus,
    netIncome,
    incomeSource,
    otherIncomeAmount,
    otherDeductibles,
    totalMonthlyLoanRepayment
  } = loanApplication;

  const twoThirdsRuleArgs = {
    totalMonthlyLoanPayments: totalMonthlyLoanRepayment,
    netIncome,
    otherIncomeAmount,
    otherDeductibles,
    repaymentAmount
  };

  const { passesTwoThirdsRule, totalMonthlyNetIncome, totalAvailableForRepayment } = getTwoThirdsRuleValues(
    twoThirdsRuleArgs
  );
  const hasViolatedRule = !passesTwoThirdsRule;

  React.useEffect(() => {
    if (hasViolatedRule && !data.bypass && !isV1) {
      disableNextButton();
    }
  }, [data.bypass, disableNextButton, hasViolatedRule, passesTwoThirdsRule, isV1]);

  const attributes = React.useMemo(
    function getMemoizedAttributes() {
      const rows = [
        [
          {
            title: <Text id="LoanAppraisal.IncomeDetails.employmentType" />,
            value: employmentStatus ? <Text id={`Employment.Type.${employmentStatus}`} /> : null
          },
          {
            title: <Text id="LoanAppraisal.IncomeDetails.totalMonthlyNetIncome" />,
            value: <Currency format="currency" value={totalMonthlyNetIncome} />
          },
          {
            title: <Text id="LoanAppraisal.IncomeDetails.totalAvailableForRepayment" />,
            value: <Currency format="currency" value={totalAvailableForRepayment} />
          }
        ],
        [
          { title: <Text id="LoanAppraisal.IncomeDetails.primaryBusiness" />, value: profession },
          {
            title: <Text id="LoanAppraisal.IncomeDetails.monthlyIncome" />,
            value: <Currency format="currency" value={netIncome} />
          }
        ],
        [
          {
            title: <Text id="LoanAppraisal.IncomeDetails.secondaryBusiness" />,
            value: incomeSource
          },
          {
            title: <Text id="LoanAppraisal.IncomeDetails.secondaryIncome" />,
            value: <Currency format="currency" value={otherIncomeAmount} />
          }
        ]
      ];

      return rows;
    },
    [
      employmentStatus,
      incomeSource,
      netIncome,
      otherIncomeAmount,
      profession,
      totalAvailableForRepayment,
      totalMonthlyNetIncome
    ]
  );

  return (
    <>
      <Container.Root>
        <Container.Body>
          {isV1 ? null : (
            <If
              condition={hasViolatedRule}
              do={
                <Banner
                  state="error"
                  className={styles['banner']}
                  text={
                    <>
                      <h3 className={styles['banner__title']}>
                        <Text id="LoanAppraisal.IncomeDetails.OneThirdRuleViolated.title" />
                      </h3>

                      <p className={styles['banner__message']}>
                        <span>
                          <Text id="LoanAppraisal.IncomeDetails.OneThirdRuleViolated.message" />
                        </span>
                        <LinkButton as="button" onClick={showModal}>
                          <Text id="LoanAppraisal.IncomeDetails.OneThirdRuleViolated.viewMoreDetails" />
                        </LinkButton>
                      </p>
                    </>
                  }
                />
              }
              else={
                <Banner
                  state="success"
                  className={styles['banner']}
                  text={
                    <>
                      <h3 className={cx('kw-text-large', styles['banner__title'])}>
                        <Text id="LoanAppraisal.IncomeDetails.OneThirdRuleObserved.title" />
                      </h3>

                      <p className={cx('kw-text-regular', styles['banner__message'])}>
                        <Text
                          id="LoanAppraisal.IncomeDetails.OneThirdRuleObserved.message"
                          values={{
                            firstName: member.firstName,
                            amount: <Currency format="currency" value={totalAvailableForRepayment} />
                          }}
                        />
                      </p>
                    </>
                  }
                />
              }
            />
          )}

          {attributes.map((row, index) => (
            <AttributesRow key={index.toString()} attributes={row} />
          ))}
        </Container.Body>
      </Container.Root>

      <If
        condition={shownModal}
        do={
          <TwoThirdsModal
            {...twoThirdsRuleArgs}
            member={member}
            to={{ pathname: `/loans/${loan.id}/appraisal/loanDetails/1`, state: { shouldModify: true } }}
            btnTextId="LoanAdd.Configure.TwoThirdsModal.Confirm.button.extendedRepaymentPeriod"
            onBypass={() => {
              addDataAndContinue({ bypass: true });
              hideModal();
            }}
          />
        }
      />
    </>
  );
}
