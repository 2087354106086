import React from 'react';
import { Currency, Text } from '@kwara/components/src/Intl';
import { If } from '@kwara/components/src/If/If';

import { default as BaseTable, Heading, Row, Cell } from '../../../../../../components/Table';

import styles from './Table.module.css';

type Props = {
  principalAmount: number;
  netDisbursalAmount: number;
  disbursementFees?: { name: string; amount: number }[];
  refinancings?: { loanName: string; loanId: string; amount: number }[];
};

export function DisbursementPreviewTable({
  principalAmount,
  netDisbursalAmount,
  disbursementFees = [],
  refinancings = []
}: Props) {
  const heading = (
    <Row>
      <Heading translationId="LoanAppraisal.DisbursementPreview.Table.header.name" />
      <Heading align="right" width="150px" translationId="LoanAppraisal.DisbursementPreview.Table.header.amount" />
    </Row>
  );

  return (
    <BaseTable
      heading={heading}
      footer={
        <Row className={styles['net-disbursal-amount']}>
          <Cell className="b">
            <Text id="LoanAppraisal.DisbursementPreview.Table.netDisbursalAmount" />
          </Cell>
          <Cell className="tr b">
            <Currency value={netDisbursalAmount} />
          </Cell>
        </Row>
      }
    >
      <Row>
        <Cell>
          <Text id="LoanAppraisal.DisbursementPreview.Table.principalAmount" />
        </Cell>
        <Cell className="tr">
          <Currency hideDecimals={false} value={principalAmount} />
        </Cell>
      </Row>
      <If
        condition={disbursementFees.length > 0}
        do={disbursementFees.map(fee => {
          return (
            <Row key={fee.name}>
              <Cell>
                <span className="grey-400">
                  <Text id="LoanAppraisal.DisbursementPreview.Table.disbursementFee" />{' '}
                </span>
                {fee.name}
              </Cell>
              <Cell className="tr">
                <Currency hideDecimals={false} value={-1 * fee.amount} />
              </Cell>
            </Row>
          );
        })}
        else={
          <Row>
            <Cell>
              <span className="grey-400">
                <Text id="LoanAppraisal.DisbursementPreview.Table.disbursementFee.empty" />
              </span>
            </Cell>
            <Cell className="tr">
              <Currency hideDecimals={false} value={0} />
            </Cell>
          </Row>
        }
      />
      <If
        condition={refinancings.length > 0}
        do={refinancings.map(refinancing => {
          return (
            <Row key={refinancing.loanId}>
              <Cell>
                <span className="grey-400">
                  <Text id="LoanAppraisal.DisbursementPreview.Table.settlement" />{' '}
                </span>{' '}
                {refinancing.loanName} ({refinancing.loanId})
              </Cell>
              <Cell className="tr">
                <Currency hideDecimals={false} value={-1 * refinancing.amount} />
              </Cell>
            </Row>
          );
        })}
        else={
          <Row>
            <Cell>
              <span className="grey-400">
                <Text id="LoanAppraisal.DisbursementPreview.Table.settlement.empty" />
              </span>
            </Cell>
            <Cell className="tr">
              <Currency hideDecimals={false} value={0} />
            </Cell>
          </Row>
        }
      />
    </BaseTable>
  );
}
